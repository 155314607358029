<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.outsource_add") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submitPatient(true)"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('formPatient')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-main>
      <el-row :gutter="24">
        <!-- Поиск -->
        <el-col :span="10">
          <form
            class="mt-2 width-100 d-flex flex-wrap"
            v-on:submit.prevent="findOrder"
          >
            <el-input
              :placeholder="$t('message.enter_id')"
              v-model="id"
              class="input-with-select"
            >
              <el-button
                @click="findOrder()"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </form>
        </el-col>

        <!-- Таблица пациента -->
        <el-col v-if="order_show" :span="24" class="mt-2">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span> {{ $t("message.patient") }}</span>
            </div>
            <div>
              <el-table :data="tablePatientData" style="width: 100%">
                <el-table-column label="№" width="110">
                  <template slot-scope="scope"> # {{ scope.row.id }} </template>
                </el-table-column>
                <el-table-column
                  prop="first_name"
                  :label="$t('message.first_name')"
                  width="150"
                >
                </el-table-column>
                <el-table-column prop="surname" :label="$t('message.surname')">
                </el-table-column>
                <el-table-column
                  prop="patronymic"
                  :label="$t('message.patronymic')"
                >
                </el-table-column>
                <el-table-column prop="born_date" :label="$t('message.dob')">
                </el-table-column>
                <el-table-column prop="gender" :label="$t('message.gender')">
                </el-table-column>
                <el-table-column prop="code" :label="$t('message.code')">
                </el-table-column>
                <el-table-column prop="phone" :label="$t('message.phone')">
                </el-table-column>
                <el-table-column :label="$t('message.social')">
                  <template slot-scope="scope">
                    {{ scope.row.social ? scope.row.social.name : "" }}
                  </template>
                </el-table-column>
                <el-table-column prop="created_at" :label="$t('message.date')">
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>

        <!-- Таблица заказать услугу -->
        <el-col v-if="order_show" :span="24" class="mt-2">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span> {{ $t("message.order_service") }}</span>
            </div>
            <div>
              <el-table :data="tableServicesData" style="width: 100%">
                <el-table-column type="index" label="#" width="80">
                </el-table-column>
                <el-table-column
                  prop="name"
                  :label="$t('message.service_name')"
                  width="280"
                >
                </el-table-column>
                <el-table-column prop="price" :label="$t('message.price2')">
                  <template slot-scope="scope">
                    {{ scope.row.price | formatMoney }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="discount_price"
                  :label="$t('message.discount')"
                >
                </el-table-column>
                <el-table-column prop="code" :label="$t('message.code')">
                </el-table-column>
                <el-table-column prop="lifetime" label=" Срок услуги ">
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-main>

    <div class="grid-content" v-if="order_show">
      <div class="add_patient">
        <el-form ref="formPatient" :model="form" :rules="rules" class="twoform">
          <el-row :gutter="24">
            <el-col :span="12">
              <!-- Загрузка файлов -->
              <el-upload
                class="upload-demo"
                drag
                :action="base_url + 'outsourcing/storeFile'"
                :headers="{
                  Authorization: 'Bearer ' + $store.getters.token,
                }"
                :file-list="form.fileList"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  <em>{{ $t("message.file_upload") }}</em>
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loadingButton: false,
      loading: false,
      order_show: false,
      tablePatientData: [],
      tableServicesData: [],
      id: "",
      form: {
        fileList: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      rules: "outsource/rules",
      model: "orders/relation_model",
    }),
  },
  methods: {
    ...mapActions({
      save: "outsource/store",
      showModel: "orders/relationShow",
    }),
    handleRemove(file, fileList) {
      this.fileList = [];
      this.form.outsourcing_file_id = "";
    },
    handleSuccess(val) {
      this.form.outsourcing_file_id = val.data.file.id;
    },
    loadModel() {
      this.tablePatientData = [JSON.parse(JSON.stringify(this.model.patient))];
      this.tableOrderData = [JSON.parse(JSON.stringify(this.model))];
      this.tableServicesData = JSON.parse(
        JSON.stringify(this.model.order_services)
      );
    },
    findOrder() {
      this.loading = true;
      const query = { id: this.id, relation: true, free_orders: true };
      this.showModel(query)
        .then((res) => {
          this.order_show = true;
          this.loading = false;
          this.loadModel();
        })
        .catch((err) => {
          this.loading = false;
          this.order_show = false;
          this.$notify({
            title: "Ошибка ",
            type: "error",
            offset: 130,
            dangerouslyUseHTMLString: true,
            message: "Заказ не найден",
          });
        });
    },
    fetchData() {
      const query = { id: this.order_payments.id, relation: true };
      this.showModel(query)
        .then((res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    resetForm(formName) {
      if (this.$refs[formName]) this.$refs[formName].resetFields();

      this.$emit("c-close", { drawer: "drawerCreatePatient" });
    },
    submitPatient(close = true) {
      this.$refs["formPatient"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.order_id = this.id;
          this.save(this.form)
            .then((res) => {
              this.$alert(res);
              this.form = {};
              if (close) {
                this.loadingButton = false;
                this.$emit("c-close", {
                  reload: true,
                  drawer: "drawerCreatePatient",
                });
                this.$refs["formPatient"].resetFields();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
  watch: {
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.loadOrder();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.el-table .cell {
  word-break: break-word;
  line-height: 140%;
  text-align: center;
}
</style>